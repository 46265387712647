/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React from 'react';
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {styled} from '@mui/material/styles';
import AccordionWithOtherWidget from '@/components/common/AccordionWithOtherWidget';
import NewCheckField from '@/components/common/NewFields/NewCheckField';
import NewCurrencyField from '@/components/common/NewFields/NewCurrencyField';
import NewDateField from '@/components/common/NewFields/NewDateField';
import NewProjectField from '@/components/common/NewFields/NewProjectField';
import NewSelectField from '@/components/common/NewFields/NewSelectField';
import NewSelectPictureField from '@/components/common/NewFields/NewSelectPictureField';
import NewSwitchField from '@/components/common/NewFields/NewSwitchField';
import NewTextField from '@/components/common/NewFields/NewTextField';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import NewUploadField from '@/components/common/NewFields/NewUploadField';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  ToggleOffOutlined,
  FormatSizeOutlined,
  List,
  PhotoOutlined,
  PaidOutlined,
  CalendarMonthOutlined,
  CheckBoxOutlined,
  FileUploadOutlined,
  ChecklistOutlined,
  EmailOutlined,
  InsertLinkOutlined,
  TagOutlined,
  PhoneOutlined,
  MenuBookOutlined,
  StairsOutlined,
  ListAltOutlined,
  MultipleStopOutlined
} from '@mui/icons-material';
import type {Event, Events, Resource, Resources} from '@/@types/enum';

const fieldIcons = [
  {
    field_type: 'text',
    icon: <FormatSizeOutlined />
  },
  {
    field_type: 'multiplechoice',
    icon: <List />
  },
  {
    field_type: 'choice',
    icon: <List />
  },
  {
    field_type: 'image',
    icon: <PhotoOutlined />
  },
  {
    field_type: 'date',
    icon: <CalendarMonthOutlined />
  },
  {
    field_type: 'datetime',
    icon: <CalendarMonthOutlined />
  },
  {
    field_type: 'boolean',
    icon: <CheckBoxOutlined />
  },
  {
    field_type: 'file',
    icon: <FileUploadOutlined />
  },
  {
    field_type: 'boolean',
    icon: <ToggleOffOutlined />
  },
  {
    field_type: 'currency',
    icon: <PaidOutlined />
  },
  {
    field_type: 'standard',
    icon: <ChecklistOutlined />
  },
  {
    field_type: 'email',
    icon: <EmailOutlined />
  },
  {
    field_type: 'link',
    icon: <InsertLinkOutlined />
  },
  {
    field_type: 'url',
    icon: <InsertLinkOutlined />
  },
  {
    field_type: 'number',
    icon: <TagOutlined />
  },
  {
    field_type: 'phone_number',
    icon: <PhoneOutlined />
  },
  {
    field_type: 'phone',
    icon: <PhoneOutlined />
  },
  {
    field_type: 'menu_book',
    icon: <MenuBookOutlined />
  },
  {
    field_type: 'stairs',
    icon: <StairsOutlined />
  },
  {
    field_type: 'form',
    icon: <ListAltOutlined />
  },
  {
    field_type: 'pdfgenerator',
    icon: <SummarizeIcon />
  },
  {
    field_type: 'decimal',
    icon: <MultipleStopOutlined />
  },
  {
    field_type: 'positionblock',
    icon: <FmdGoodOutlinedIcon />
  }
];

export const handleIcons = (type: string) => {
  const icon = fieldIcons.filter(
    item => item.field_type === type?.toLowerCase()
  );
  return icon[0]?.icon || <MenuBookOutlined />;
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const componentMap: {[key: string]: React.FC} = {
  AccordionWithOtherWidget,
  NewTextField,
  NewDateField,
  NewSelectField,
  NewSelectPictureField,
  NewCurrencyField,
  NewSwitchField,
  NewUploadField,
  NewCheckField,
  NewProjectField
  // Add more components to the map as needed
};

export const DynamicComponentRenderer: React.FC<{
  componentName: string;
  detail: any;
}> = ({componentName, detail}) => {
  const SelectedComponent = componentMap[componentName];

  if (!SelectedComponent) {
    return <div>{'Component not found'}</div>;
  }

  return <SelectedComponent {...detail} />;
};

export const getTenant = () => {
  const currentUrl = new URL(window.location.href);
  const regex = /tenant\/([^/]+)/;
  const match = currentUrl.pathname.match(regex);
  return match?.[1];
};

export const getActiveTab = () => {
  const currentUrl = new URL(window.location.href);
  const currentPage = currentUrl.toString().split('/')[-1];
  let tab = '';
  switch (currentPage) {
    case 'settings':
      tab = 'general';
      break;
    case 'admin':
      tab = 'user_management';
      break;
    case 'tasks':
      tab = 'task_overview_list';
      break;
    default:
      tab = '';
      break;
  }
  return tab;
};

export const validateEmail = (email: string) => {
  if (email === '') {
    return false;
  }
  return email
    .toLowerCase()
    .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
};

export const validateUrl = (url: string) => {
  const pattern = new RegExp(
    '^(?:(?:(?:https?|ftp):)?\\/\\/)' + // protocol
      '(?:\\S+(?::\\S*)?@)?' + // auth
      '(?:' +
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' + // IP address exclusion
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' + // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
      ')' +
      '(?::\\d{2,5})?' + // port
      '(?:[/?#]\\S*)?' + // path, query string, fragment
      '$',
    'i'
  );
  return pattern.test(url?.toLowerCase());
};

export const validateNumber = (num: string) => {
  const stringNum = JSON.stringify(num);
  if (stringNum.includes(',') || stringNum.includes('.')) {
    return false;
  }

  const number = typeof num === 'string' ? Number(num) : num;

  if (Number.isNaN(number)) {
    return false;
  }

  return Number.isInteger(number);
};

export const validateDecimal = (input: string) => {
  const regex = /^(-?(\d+([.,]\d{0,2})?)?)?$/;
  return regex.test(input);
};

export const validatePhone = (input: string) => {
  const regex = /^(\+\d{1,3}\s?)?\d+$/;
  return regex.test(input);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^[+]?\d+$/);
};

export const validatePassword = (password: string) => {
  if (password === '') {
    return false;
  }
  return true;
};

export const validateText = (text: string) => {
  if (text === '') {
    return false;
  }
  return true;
};

export const validateDate = (date: Date) => {
  if (date === null) {
    return false;
  }
  return true;
};

export const validatePostCode = (input: string) => {
  const pattern = /^[a-zA-Z0-9]+$/;
  return pattern.test(input);
};

export const setCurrentTime = (date: Date) => {
  const d = new Date();
  const result = date.setHours(
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds()
  );
  return new Date(result);
};

export const parseFilter = (queryOptions: any) => {
  const params = queryOptions.filterModel?.items?.map((item: any) => {
    return item.value
      ? `${item.columnField}${checkFilter(item.operatorValue)}=${item.value}&`
      : '';
  });
  return params;
};

export const checkFilter = (val: string) => {
  let lookup;
  switch (val) {
    case '=':
      lookup = '';
      break;
    case 'starts with':
      lookup = '__startswith';
      break;
    case 'contains':
      lookup = '';
      break;
    case 'notContains':
      lookup = '__contains!';
      break;
    case 'ends with':
      lookup = '__endswith';
      break;
    case '!=':
      lookup = '!';
      break;
    case 'dateIs':
      lookup = '__date';
      break;
    case 'dateIsNot':
      lookup = '__date!';
      break;
    case 'onOrBefore':
      lookup = '_range_before';
      break;
    case 'onOrAfter':
      lookup = '_range_after';
      break;
    case '<':
      lookup = '__lt';
      break;
    case '>':
      lookup = '__gt';
      break;
    case '<=>':
      lookup = '__lte';
      break;
    case '>=':
      lookup = '__gte';
      break;
    default:
      lookup = '';
      break;
  }
  return lookup;
};

export const randomColor = (str: string) => {
  const hashCode = (str: string) =>
    str
      .replace(' ', '')
      .split('')
      .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
  const texthash = hashCode(str);
  return `hsl(${texthash % 360}, 70%, 70%)`;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 20,
      height: 20,
      fontSize: 10
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
};

export const checkStatusColor = (status: string) => {
  switch (status) {
    case 'At risk':
      return 'warning';
    case 'Off track':
      return 'error';
    case 'Completed':
      return 'success';
    default:
      return 'primary';
  }
};

export const hexToRgba = (hex: string, opacity = 1) => {
  if (!hex.startsWith('#')) {
    throw new Error('Invalid hex color format');
  }
  hex = hex.slice(1);

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }

  if (hex.length !== 6) {
    throw new Error('Invalid hex color length');
  }

  const r = Number.parseInt(hex.substring(0, 2), 16);
  const g = Number.parseInt(hex.substring(2, 4), 16);
  const b = Number.parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const truncateFileName = (fileName: string, maxLength: number) => {
  if (fileName.length < maxLength) {
    return fileName;
  }

  const name = fileName.split('.')[0];
  const extension = fileName.split('.')[1];

  const truncatedName = `${name.substring(0, maxLength)} ...`;
  return truncatedName + extension;
};

export const getDayOfWeek = (num: number) => {
  switch (num) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    default:
      break;
  }
};

export const getOrdinalNum = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const validDay = (day: string) => {
  switch (day) {
    case 'monday':
      return true;
    case 'tuesday':
      return true;
    case 'wednesday':
      return true;
    case 'thursday':
      return true;
    case 'friday':
      return true;
    case 'saturday':
      return true;
    case 'sunday':
      return true;
    default:
      return false;
  }
};

export const calculateHourDifferenceWithoutPeriod = (
  time1: string,
  time2: string
) => {
  // Convert 12-hour format times to 24-hour format
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  // Calculate the difference in minutes
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;
  const minuteDifference = (totalMinutes2 - totalMinutes1 + 1440) % 1440;

  // Calculate the difference in hours
  const hourDifference = Math.floor(minuteDifference / 60);

  return hourDifference;
};

export const validateDuration = (duration: string) => {
  if (/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(duration)) {
    return true;
  }
  return false;
};

export const validateDateRange = (start: Date, end: Date): boolean => {
  if (
    new Date(start).setHours(0, 0, 0, 0) === new Date(end).setHours(0, 0, 0, 0)
  ) {
    return true;
  }
  return (
    new Date(start).setHours(0, 0, 0, 0) < new Date(end).setHours(0, 0, 0, 0)
  );
};

export const checkCycle = (cycle: string) => {
  let val;
  switch (cycle) {
    case 'Yearly':
      val = 0;
      break;
    case 'Daily':
      val = 3;
      break;
    case 'Weekly':
      val = 2;
      break;
    case 'Monthly':
      val = 1;
      break;
    default:
      val = null;
      break;
  }
  return val;
};

export const frequencyCycle = (num: number | null) => {
  let val;
  switch (num) {
    case 0:
      val = 'Yearly';
      break;
    case 3:
      val = 'Daily';
      break;
    case 2:
      val = 'Weekly';
      break;
    case 1:
      val = 'Monthly';
      break;
    default:
      val = 'no_repeat';
      break;
  }
  return val;
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const extractValues = (data: any, keys: any) => {
  return data.map((item: any) => {
    const extracted: any = {};
    keys.forEach(({key}: any) => {
      const value = key
        .split('.')
        .reduce((obj: any, subKey: any) => (obj ? obj[subKey] : null), item);
      extracted[key] = value;
    });
    return extracted;
  });
};

export const exportToExcel = async (
  data: any,
  keys: any,
  fileName = 'export.xlsx'
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  const extractedData = extractValues(data, keys);

  worksheet.columns = keys.map((key: any) => ({
    header: key.label,
    key: key.key
  }));

  extractedData.forEach((item: any) => {
    worksheet.addRow(item);
  });

  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName);
};

export const getStatusColor = (status: string, color?: string) => {
  let code = '';
  switch (status) {
    case 'todo':
      code = '#144A6C';
      break;
    case 'in_progress':
      code = '#144A6C';
      break;
    case 'done':
      code = color;
      break;
    case 'active':
      code = '#42AEA7';
      break;
    default:
      code = '#144A6C';
      break;
  }
  return code;
};

export const checkCurrency = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'JPY':
      return '¥';
    case 'AUD':
      return 'AU$';
    case 'CAD':
      return 'CAD$';
    default:
      return '$';
  }
};

export const decodeJWT = (token: string) => {
  if (!token) {
    return;
  }
  const parts = token?.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT format');
  }
  const payload = Buffer.from(parts[1], 'base64').toString();
  return JSON.parse(payload);
};

export const checkHak4tInstance = () => {
  const accessTk = localStorage.getItem('accessToken');

  return decodeJWT(accessTk)?.organization?.hak4t_features_enabled;
};

export const transformEventWorkplan = (
  apiEvents: Events,
  selectedResource?: string
) => {
  return apiEvents?.map((apiEvent: Event) => ({
    id: apiEvent.id,
    title: apiEvent.project.name,
    start: apiEvent.start_at.split('T')[0],
    end: apiEvent.end_at.split('T')[0],
    color: apiEvent.color,
    description: apiEvent.description,
    resourceId:
      selectedResource === 'stages'
        ? apiEvent.stage?.id
        : selectedResource === 'colours'
          ? apiEvent.color
          : selectedResource === 'icons'
            ? apiEvent.icon?.id
            : null,
    resourceIds:
      selectedResource === 'tags'
        ? apiEvent.tags?.flatMap((tag: any) => tag.id)
        : selectedResource === 'employees'
          ? apiEvent.assignees?.flatMap((emp: any) => emp.id)
          : [],
    duration: '24:00:00',
    hour: '1',
    extendedProps: {
      icon: apiEvent.icon,
      project: apiEvent.project?.id,
      stageName: apiEvent.stage?.name,
      resourceType: selectedResource
    }
  }));
};

export const transformEventTaskplan = (
  apiEvents: Events,
  selectedResource?: string
) => {
  return apiEvents.map((apiEvent: Event) => ({
    id: apiEvent.id,
    title: apiEvent.task.title,
    start: apiEvent.start_at.split('T')[0],
    end: apiEvent.end_at.split('T')[0],
    color: apiEvent.color,
    description: apiEvent.description,
    resourceId:
      selectedResource === 'projects'
        ? apiEvent.task.project?.id
        : selectedResource === 'stages'
          ? apiEvent.task.step.stage
          : selectedResource === 'colours'
            ? apiEvent.color
            : selectedResource === 'icons'
              ? apiEvent.icon?.id
              : selectedResource === 'employees'
                ? apiEvent.task?.assignee.id
                : selectedResource === 'reporter'
                  ? apiEvent.task?.reporter.id
                  : null,
    resourceIds:
      selectedResource === 'tags'
        ? apiEvent.tags?.flatMap((tag: any) => tag.id)
        : selectedResource === 'employees'
          ? apiEvent.assignees?.flatMap((emp: any) => emp.id)
          : [],
    duration: '24:00:00',
    extendedProps: {
      icon: apiEvent.icon,
      project: apiEvent.project?.id,
      resourceType: selectedResource
    }
  }));
};

export const transformEventHoliday = (
  apiEvents: any,
  isToShowHolidays?: boolean
) => {
  if (!isToShowHolidays) {
    return [];
  }
  return apiEvents.map((apiEvent: any) => ({
    start: apiEvent?.date,
    end: apiEvent?.date,
    display: 'background',
    color: '#ceeded'
  }));
};

export const transformResource = (
  apiResources: Resources,
  selectedResource?: any
) => {
  if (apiResources) {
    return apiResources.map((apiResource: Resource) => ({
      id: selectedResource === 'colours' ? apiResource.color : apiResource.id,
      title: apiResource.name || apiResource.title
    }));
  }
};
