/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosInstance from '@/components/utils/axiosInstance';
import type {AxiosError, AxiosResponse} from 'axios';

const base = '/api/form-field';

export const createFormFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const createImageFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  const configPost = {
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data'
    }
  };
  return axiosInstance
    .post(`${base}/`, payload, configPost)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getFieldChoices = (params?: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(
      'api/form-field-choice/filter/',
      {
        ...params
      },
      config
    )
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getFormFields = (params?: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(
      `${base}/filter/`,
      {
        ...params
      },
      config
    )
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const updateFormFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .patch(`${base}/${payload.id}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const updateImageFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  const configPost = {
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data'
    }
  };
  return axiosInstance
    .patch(`${base}/${payload.id}/`, payload.fieldData, configPost)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const restoreFormFields = (userId: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/${userId}/restore/`, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const deleteFormFields = (userId: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/${userId}/soft_delete/`, {}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
