/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosInstance from '@/components/utils/axiosInstance';
import type {AxiosError, AxiosResponse} from 'axios';

const base = '/api/position-field';

export const createPositionFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getPositionFields = (params?: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(
      `${base}/filter/`,
      {
        filters: params?.filter ? params.filter : undefined,
        search: params?.search,
        pagination: {
          page: params?.page,
          page_size: params?.page_size
        }
      },
      config
    )
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const updatePositionFields = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .patch(`${base}/${payload.id}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const restorePositionFields = (userId: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/${userId}/restore/`, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const deletePositionFields = (userId: string) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/${userId}/soft_delete/`, {}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getPositionFieldChoices = (params?: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(
      'api/position-field-choice/filter/',
      {
        ...params
      },
      config
    )
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
