/* eslint-disable require-jsdoc */
// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}
// function authPath(root: string, sublink: string, org: boolean) {
//   if (org)
//     return `${root}${sublink}`;
//   return `${root}${sublink}`;
// }

const ROOTS_AUTH = '/authentication';
const ROOTS_DASHBOARD = '/dashboard';
// const ROOTS_PUBLIC = '/auth';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  publicRoot: ROOTS_AUTH,
  // teamName: authPath(ROOTS_AUTH, '/teamName', false),
  forgotDomainEmail: path(ROOTS_AUTH, '/domain/forgot'),
  forgotDomainEmailSent: path(ROOTS_AUTH, '/domain/forgot/sent'),
  login: path(ROOTS_AUTH, '/login'),
  registerForm: path(ROOTS_AUTH, '/register'),
  registerEmailSent: path(ROOTS_AUTH, '/register/email/sent'),
  registerEmailVerify: path(ROOTS_AUTH, '/email/verify'),
  companyRegisterForm: path(ROOTS_AUTH, '/register/company'),
  forgotPasswordEmail: path(ROOTS_AUTH, '/password/forgot/email'),
  forgotPasswordEmailSent: path(ROOTS_AUTH, '/password/email/sent'),
  resetPassword: path(ROOTS_AUTH, '/password/reset'),
  organizations: path(ROOTS_AUTH, '/organizations')
};

export const PATH_PAGE = {
  page404: '*'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  facilities: path(ROOTS_DASHBOARD, '/facilities'),
  planboard: path(ROOTS_DASHBOARD, '/planboard'),
  planboard_archived: path(ROOTS_DASHBOARD, '/planboard/archived'),
  settings: path(ROOTS_DASHBOARD, '/settings'),
  settings_newfield: path(ROOTS_DASHBOARD, '/settings/new_field'),
  settings_newform: path(ROOTS_DASHBOARD, '/settings/new_form'),
  settings_newPosition: path(ROOTS_DASHBOARD, '/settings/position'),
  settings_newpositionfield: path(
    ROOTS_DASHBOARD,
    '/settings/new_position_field'
  ),
  projects_archived: path(ROOTS_DASHBOARD, '/archived_projects'),
  settings_admin: path(ROOTS_DASHBOARD, '/settings/admin'),
  settings_step_templates: path(ROOTS_DASHBOARD, '/settings/step_templates'),
  reports: path(ROOTS_DASHBOARD, '/reports'),
  department: path(ROOTS_DASHBOARD, '/department'),
  relations: path(ROOTS_DASHBOARD, '/relations'),
  users: path(ROOTS_DASHBOARD, '/users'),
  meetings: path(ROOTS_DASHBOARD, '/meetings'),
  contacts: path(ROOTS_DASHBOARD, '/contacts'),
  info: path(ROOTS_DASHBOARD, '/info'),
  surveys: path(ROOTS_DASHBOARD, '/surveys'),
  plugins: path(ROOTS_DASHBOARD, '/plugins'),
  tasks: path(ROOTS_DASHBOARD, '/tasks'),
  project: path(ROOTS_DASHBOARD, '/project/:projectId'),
  department_details: path(
    ROOTS_DASHBOARD,
    '/settings/departments/:departmentId'
  ),
  user_details: path(ROOTS_DASHBOARD, '/settings/users/:userId'),
  client_details: path(ROOTS_DASHBOARD, '/relations/:userId')
};

export const getDashboardPaths = () => {
  return {
    root: path(ROOTS_DASHBOARD, ''),
    planning: path(ROOTS_DASHBOARD, '/planning'),
    tasks: path(ROOTS_DASHBOARD, '/tasks'),
    clients: path(ROOTS_DASHBOARD, '/clients'),
    services: path(ROOTS_DASHBOARD, '/services'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    settings_newfield: path(ROOTS_DASHBOARD, '/settings/new_field'),
    settings_newform: path(ROOTS_DASHBOARD, '/settings/new_form'),
    settings_newPosition: path(ROOTS_DASHBOARD, '/settings/position'),
    settings_newpositionfield: path(
      ROOTS_DASHBOARD,
      '/settings/new_position_field'
    ),
    settings_admin: path(ROOTS_DASHBOARD, '/settings/admin'),
    settings_step_templates: path(ROOTS_DASHBOARD, '/settings/step_templates'),
    employees: path(ROOTS_DASHBOARD, '/employees'),
    contacts: path(ROOTS_DASHBOARD, '/contacts'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
    relations: path(ROOTS_DASHBOARD, '/relations'),
    users: path(ROOTS_DASHBOARD, '/users'),
    meetings: path(ROOTS_DASHBOARD, '/meetings'),
    hours: path(ROOTS_DASHBOARD, '/hours'),
    plugins: path(ROOTS_DASHBOARD, '/plugins'),
    admin: path(ROOTS_DASHBOARD, '/admin'),
    project: path(ROOTS_DASHBOARD, '/project/:projectId'),
    department_details: path(
      ROOTS_DASHBOARD,
      '/settings/departments/:departmentId'
    ),
    user_details: path(ROOTS_DASHBOARD, '/settings/users/:userId'),
    client_details: path(ROOTS_DASHBOARD, '/relations/:userId')
  };
};
